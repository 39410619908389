import styled from "@emotion/styled";
import "./styles.css";

export const Spinner = styled.div`
  display: inline-block;
  border-top: 3px solid white;
  border-right: 3px solid white;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 43x;
  border-left-width: 43x;
  border-bottom-color: transparent;
  border-left-color: transparent;
  width: 1em;
  height: 1em;
  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-name: loading-circle;
  animation-timing-function: linear;
`;
