import React, { createRef, useState } from "react";

import styled from "@emotion/styled";
import emailjs from "emailjs-com";
import { graphql, useStaticQuery } from "gatsby";
import { isMobile } from "react-device-detect";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import { StyledInput } from "../components/input";
import { Container, Wrapper } from "../components/layout";
import { StyledButtonPill } from "../components/layout/button";
import { Layout } from "../components/layout/layout";
import { Spinner } from "../components/spinner/spinner";
import { FromBottom } from "../components/transitions";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;
  @media (max-width: 850px) {
    width: 100%;
  }
`;

const Contact = () => {
  const [state, setState] = useState({});
  const [disabled, setDisabled] = useState(true);

  const ref = createRef();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "contact.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [isLoading, setIsLoading] = useState(false);

  const notify = (message: string) => toast(message);

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      await emailjs.send(
        process.env.GATSBY_SERVICE_ID,
        process.env.GATSBY_TEMPLATE_ID,
        state,
        process.env.GATSBY_PUBLIC_KEY
      );

      notify("Votre message a bien été envoyé !");
      setState({});
    } catch (e) {
      console.log(e);
      notify("Une erreur s'est produite, veuillez réessayer plus tard.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout background={data.hero} title="Contactez-nous">
      <Helmet>
        <title>Centre Léveil - Contactez-nous</title>
      </Helmet>
      <Wrapper id="#form">
        {/* <FromBottom isActive={!isMobile}> */}
        <Container style={{ flexDirection: "column" }}>
          <h2>
            Remplissez le formulaire ci-dessous pour qu&apos;on communique avec
            vous
          </h2>
          <Form name="contact" method="post" onSubmit={onSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" value="bot-field" />
            <StyledInput
              placeholder="Votre nom"
              name="name"
              required
              onChange={handleChange}
            />
            <StyledInput
              placeholder="Votre courriel"
              type="email"
              name="email"
              required
              onChange={handleChange}
            />
            <StyledInput
              placeholder="Votre numéro de téléphone"
              name="phone"
              required
              onChange={handleChange}
            />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <ReCAPTCHA
              ref={ref}
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              style={{ alignSelf: "center" }}
              theme="dark"
              onChange={() => setDisabled(false)}
            />
            <StyledButtonPill
              type="submit"
              variant="contained"
              style={{ marginTop: "1rem", alignSelf: "center" }}
              disabled={disabled}
            >
              {isLoading ? <Spinner /> : "Envoyer"}
            </StyledButtonPill>
          </Form>
        </Container>
        {/* </FromBottom> */}
      </Wrapper>
    </Layout>
  );
};

export default Contact;
